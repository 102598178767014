<template>
  <component
    :is="targetComponent"
    v-model="_value"
    v-on="$listeners"
    v-bind="targetComponentProps"
    :name="name"
    ref="editor"
    @toggleBaseSpellChecker="toggleEditorSpellChecker"
    :mentions="macroIntellisense"
    @initialized="onInitiailized"
    @customize-modules="customizeQuillModules"
  />
</template>

<script>
import { mapGetters, mapState } from "vuex";
import MacroModule, { macroStore } from "@/modules/Quill/MacroIntellisense.js";
import { MacroTypeEnum } from "@/modules/enums";
import { fromEvent } from "rxjs";
import { filter, switchMap, tap } from "rxjs/operators";
import Macro from "@/modules/Quill/Macro";
import Quill from "devextreme-quill";
import { isModalOpen } from "@/modules/helpers";
import { getDefaultStyles } from "@/modules/getDefaultStylesByField";

Quill.register({ "modules/macro": Macro });

export const regularExpressions = {
  group: /\.\.([rgpsmd|\s])$/i,
  single: /([.|\\])([\w\-+]+(;[dmsc])?)/i,
  decimalNumber: /[0-9]+[.]([0-9]+)/i,
  specimen: /((?:([.|\\])([\w\-+]+(;[dmsc])?))+),([\w*>=<]+)/i,
  specimenWithBlock: /(?:([.|\\])([\w\-+]+(;[dmsc])?))+,([\w*>=<]+)(,[\w])/i,
  block: /((?:[.][\w+-]+)+),(=?[\w*><=]{1,3}),([\w*><=]+)/i,
  singleWithoutDot: /^([\w\-+]+)(([.|\\])([\w\-+]+(;[dmsc])?))+/i,
  specimenWithoutDot: /((^([\w\-+]+(;[dmsc])?))+),([\w*>=<]+)/i
};

export default {
  inheritAttrs: false,
  props: {
    name: {
      type: String,
      required: true
    },
    value: {
      type: String,
      default: ""
    },
    expanded: {
      type: Boolean,
      default: false
    }
  },
  components: {
    Editor: () => import("@/components/common/Editor.vue"),
    DragonEditor: () => import("@/components/common/DragonEditor.vue")
  },
  data: () => ({
    component: {},
    _editor: null,
    isSpellCheckRunning: false,
    intervalId: null,
    spellCheckIteration: 0,
    editorCheckerStatus: false,
    lastKeydown: 0
  }),
  mounted() {
    if (this.expanded) {
      this.expand();
    }
  },
  subscriptions() {
    const keydown$ = fromEvent(document, "keydown");
    const checkerDone$ = this.$watchAsObservable("editorCheckerStatus", { immediate: true }).pipe(
      filter(({ oldValue, newValue }) => {
        return oldValue === true && newValue === false;
      })
    );
    const typeCharacter$ = keydown$.pipe(
      filter(({ altKey, key }) => {
        return (
          this.applicationSettings.enableSpellchecker &&
          this.SpellCheckOnSave &&
          this.$refs.editor?.isFocused &&
          key?.length === 1 &&
          !altKey &&
          !isModalOpen()
        );
      }),
      tap(() => {
        const keyDownTime = new Date().getTime();
        this.lastKeydown = keyDownTime;
        this.$emit("toggleSpellChecker", true);
        setTimeout(() => {
          if (this.lastKeydown === keyDownTime) {
            this.$emit("toggleSpellChecker", false);
          }
        }, 7000);
      }),
      switchMap(() => {
        return checkerDone$.pipe(
          tap(() => {
            this.$emit("toggleSpellChecker", false);
          })
        );
      })
    );
    return { typeCharacter$ };
  },
  watch: {
    useDragonEditors: {
      handler() {
        const { isExpanded, isFocused } = this.$refs.editor;
        if (isExpanded) {
          setTimeout(() => {
            this.expand();
            if (isFocused) {
              this.$refs.editor.focus();
            }
          }, 500);
        }
      }
    }
  },
  methods: {
    onInitiailized({ component }) {
      if (component) {
        component.register({ "modules/mentions": MacroModule }, true);
      }
      this.$emit("editorReady", this.name);
    },
    focus() {
      if (this.$refs?.editor?.focus) {
        this.$refs.editor.focus();
      }
    },
    expand() {
      if (this.$refs?.editor?.expand) {
        this.$refs.editor.expand();
      }
    },
    collapse() {
      if (this.$refs?.editor) {
        this.$refs.editor.collapse();
      }
    },
    customizeQuillModules(config) {
      config.macro = {
        name: this.name,
        styles: this.styles,
        initialized: true
      };
    },
    toggleEditorSpellChecker(status) {
      this.editorCheckerStatus = status;
    }
  },
  computed: {
    ...mapState({
      currentUser: state => state.currentUser,
      applicationSettings: state => state.applicationSettings,
      currentLab: state => state.currentLab,
      specimens: state => state.accessionStore.specimens,
      MacroSearchWithPeriod: state => state.labSettings.MacroSearchWithPeriod,
      currentSpecimen: state => state.accessionStore.currentSpecimen,
      SpellCheckOnSave: state => state.labSettings.SpellCheckOnSave,
      useDragonEditors: state => state.applicationSettings.useDragonEditors
    }),
    ...mapGetters(["permissions"]),
    webSpellCheckInstance() {
      if (this.$refs.editor) {
        return this.$refs.editor.webSpellCheckInstance;
      }
      return null;
    },
    targetComponent() {
      return this.useDragonEditors ? "DragonEditor" : "Editor";
    },
    targetComponentProps() {
      return this.useDragonEditors
        ? { ...this.$attrs, isSpellCheckRunning: this.isSpellCheckRunning }
        : { ...this.$attrs, mentions: this.macroIntellisense, onInitiailized: this.onInitiailized };
    },
    targetList() {
      return [...(this.specimens.map(e => e.specimenOrder) || []), "*", ">", "<", ">=", "<="];
    },
    _value: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      }
    },
    styles() {
      return getDefaultStyles(this.name, true);
    },
    macroIntellisense() {
      if (this.applicationSettings.macroAssist) {
        return [
          {
            displayExpr: "displayName",
            searchExpr: "displayName",
            valueExpr: "displayName",
            dataSource: macroStore(),
            marker: ".",
            minSearchLength: 2,
            itemTemplate: ({ displayName, macroType }) => {
              if (macroType === MacroTypeEnum.Results) {
                return `<strong>${displayName}</strong>`;
              }
              return displayName;
            }
          },
          {
            displayExpr: "displayName",
            searchExpr: "displayName",
            valueExpr: "displayName",
            dataSource: macroStore(),
            marker: "\\",
            minSearchLength: 2,
            itemTemplate: ({ displayName, macroType }) => {
              if (macroType === MacroTypeEnum.Results) {
                return `<strong>${displayName}</strong>`;
              }
              return displayName;
            }
          },
          {
            dataSource: this.targetList,
            marker: ",",
            minSearchLength: 0,
            isExtension: true
          },
          {
            dataSource: ["C", "D", "M", "S"],
            marker: ";",
            minSearchLength: 0,
            isExtension: true
          }
        ];
      }
      return [];
    }
  }
};
</script>
<style lang="scss" scoped>
.toolbar {
  width: 100%;
}
.title {
  text-transform: capitalize;
  font-size: 1.2rem;
}
.is-invalid {
  border-color: #dc3545 !important;
}
.is-valid {
  border-color: #28a745 !important;
}

::v-deep .dx-icon,
::v-deep .dx-dropdowneditor-icon {
  &::before {
    color: #333 !important;
  }
}
</style>
